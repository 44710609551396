<template>
  <div>
    <Loader v-if="getIsLoading" />

    <div style="padding:20px 10px;" v-else>
      <div v-if="data !== null">
        <v-card
          flat
          class=" mx-auto"
          style="padding:5px; width:95%; margin-top:30px;"
        >
          <div style="padding:0; border-bottom:1px solid #e0e0e0;">
            <v-toolbar-title
              style="text-align: center;font-size:22px; color:#500050;font-weight:bold;"
            >
              {{ data.data.loan_number }} |
              {{ new Date().toLocaleString() }}
            </v-toolbar-title>
          </div>
          <div>
            <v-toolbar-title
              style="text-align: center; font-size:18px; font-weight:bold; color:#500050;"
            >
              FORM REQUEST E-DOCUMENT
            </v-toolbar-title>
          </div>
        </v-card>

        <v-card flat class=" mx-auto" style="padding:20px 10px; width:95%;"
          ><div>
            <v-row style="padding:10px 5px;">
              <v-col
                cols="12"
                style="padding:0 15px; color:rgba(0,0,0, 0.5); display: flex; flex-direction:row;"
                ><div
                  class="booking-confirm-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-bottom:none;"
                >
                  Respondent
                </div>
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-left:none;border-bottom:none;"
                >
                  {{ data.data.employee_name }}
                </div>
              </v-col>
              <v-col
                cols="12"
                style="padding:0 15px; color:rgba(0,0,0, 0.5); display: flex; flex-direction:row;"
                ><div
                  class="booking-confirm-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-bottom:none;"
                >
                  Department
                </div>
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-left:none;border-bottom:none;"
                >
                  {{ data.data.department_name }}
                </div>
              </v-col>
              <v-col
                cols="12"
                style="padding:0 15px; color:rgba(0,0,0, 0.5); display: flex; flex-direction:row;"
                ><div
                  class="booking-confirm-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-bottom:none;"
                >
                  Document Name
                </div>
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-left:none;border-bottom:none;"
                >
                  {{ data.data.document_name }}
                </div>
              </v-col>
              <v-col
                cols="12"
                style="padding:0 15px; color:rgba(0,0,0, 0.5); display: flex; flex-direction:row;"
                ><div
                  class="booking-confirm-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-bottom:none;"
                >
                  Document No.
                </div>
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-left:none;border-bottom:none;"
                >
                  {{ data.data.document_no }}
                </div>
              </v-col>
              <v-col
                cols="12"
                style="padding:0 15px; color:rgba(0,0,0, 0.5); display: flex; flex-direction:row;"
                ><div
                  class="booking-confirm-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-bottom:none;"
                >
                  Archive No.
                </div>
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-left:none;border-bottom:none;"
                >
                  {{ data.data.archive_no }}
                </div>
              </v-col>
              <v-col
                cols="12"
                style="padding:0 15px; color:rgba(0,0,0, 0.5); display: flex; flex-direction:row;"
                ><div
                  class="booking-confirm-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-bottom:none;"
                >
                  Start Date
                </div>
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-left:none;border-bottom:none;"
                >
                  {{ data.data.loan_start_date }}
                </div>
              </v-col>
              <v-col
                cols="12"
                style="padding:0 15px; color:rgba(0,0,0, 0.5); display: flex; flex-direction:row;"
                ><div
                  class="booking-confirm-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-bottom:none;"
                >
                  End Date
                </div>
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-left:none;border-bottom:none;"
                >
                  {{ data.data.loan_end_date }}
                </div>
              </v-col>
              <v-col
                cols="12"
                style="padding:0 15px; color:rgba(0,0,0, 0.5); display: flex; flex-direction:row;"
                ><div
                  class="booking-confirm-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; "
                >
                  Purpose
                </div>
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-left:none;"
                >
                  {{ data.data.loan_purpose }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card flat class=" mx-auto" style="padding:10px 10px; width:95%;">
          <div>
            <v-row style="padding:10px 5px;">
              <v-col
                cols="12"
                style="padding:5px 15px; color:rgba(0,0,0, 0.5); display: flex; flex-direction:row;"
                ><div
                  class="booking-confirm-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%;"
                >
                  Approval Status
                </div>
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-left:none;"
                >
                  {{ data.data.status.name }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card
          v-for="(item, index) in data.approvers"
          :key="index"
          flat
          class=" mx-auto"
          style="padding:10px 10px; width:95%;"
        >
          <div>
            <v-row style="padding:10px 5px;">
              <v-col
                cols="12"
                style="padding:5px 15px 0; color:rgba(0,0,0, 0.5);"
              >
                <div
                  class="booking-confirm-table"
                  style="padding-left:10px; border:1px solid #e0e0e0; border-bottom:none;"
                >
                  Sequence #{{ item.sequence }}
                </div>
              </v-col>
              <v-col
                v-for="(i, index) in item.approver_user"
                :key="index"
                cols="12"
                style="padding:0 15px 5px; color:rgba(0,0,0, 0.5); display: flex; flex-direction:row;"
              >
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%;"
                >
                  {{ i.user_name + ' ' }}(<span style="color: #15c">{{
                    i.email
                  }}</span
                  >)
                </div>
                <div
                  class="rbs-table"
                  style="padding-left:10px;border:1px solid #e0e0e0; width:50%; border-left:none;"
                >
                  {{
                    i.status === 1
                      ? 'APPROVED'
                      : i.status === -1
                      ? 'REJECT'
                      : 'PENDING'
                  }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>

      <div v-else>
        <v-card
          flat
          class=" mx-auto"
          style="width:95%; margin-top:30px; min-height:80vh;display:flex; justify-content:center; align-items:center; "
        >
          <v-toolbar-title
            style="font-size:16px; font-weight:bold; color:rgba(0,0,0, 0.5);"
          >
            DATA NOT FOUND
          </v-toolbar-title>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Loader from '../../../components/common/loader/CircularProgress'
import { mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  data: () => ({
    eSanqua: buildType.apiURL('esanqua'),
    data: null,
    itemsPerPage: 10,
    date: null
  }),
  components: { Loader },
  created() {
    this.setIsLoading(true)
    this.verifyToken(this.$route.params.token)
  },
  computed: {
    ...mapGetters(['getIsLoading'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            timer: 10000
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    verifyToken() {
      console.log('confirm')
      axios
        .post(
          `${this.eSanqua}archive_document/loan/confirm_via_email/${this.$route.params.token}`
        )
        .then(res => {
          console.log(res)
          setTimeout(() => {
            if (res.data.status_code !== '-99') {
              this.showMsgDialog('success', res.data.status_msg)
              this.data = res.data
            } else {
              this.showMsgDialog('warning', res.data.status_msg)
            }
            this.setIsLoading(false)
          }, 2000)
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin'
          )
          this.setIsLoading(false)
        })
    }
  }
}
</script>

<style lang="scss" src=""></style>
<style>
.booking-confirm-table {
  font-weight: bold;
  color: #500050;
}
.rbs-table {
  color: #500050;
}
.table {
  border-radius: 3px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255);
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
</style>
